import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { RouterPath } from '@constants';
import HomeLayout from '@layouts/home/HomeLayout';
import { StorageService } from '@services/StorageService';
import { useRouter } from 'next/router';

const Home = () => {
    const isOpened = useMemo(() => true, []);
    const { isAuthenticated, error } = useAuth0();
    const router = useRouter();

    useEffect(() => {
        const token = StorageService.token.getValue();
        if ((!isAuthenticated || error) && !token) {
            router.push('/login');
            return;
        }
        //redirect root path to dashboard
        if (isAuthenticated && router.pathname == RouterPath.root) {
            router.push(RouterPath.drops);
            return;
        }
    }, [isAuthenticated, error, router]);

    return <HomeLayout isOpened={isOpened}>{''}</HomeLayout>;
};

export default Home;
